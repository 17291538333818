import { createPinia } from "pinia"
import piniaPluginPersist from 'pinia-plugin-persist'//引入pinia数据持久化插件

const pinia = createPinia().use(piniaPluginPersist)

export default { 
  install: (app) => {
    app.use(pinia)
  }
}

import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue'

import {
  Button,
  Modal,
  Layout,
  Menu,
  Breadcrumb,
  Input,
  Form,
  Checkbox,
  Select,
  DatePicker,
  Table,
  Tag,
  Divider,
  Pagination,
  Drawer,
  Upload,
  Popconfirm,
  Row,
  Col,
  ConfigProvider,
  Tabs,
  List,
  Dropdown,
  Tree,
  Popover,
  Avatar,
  Card,
  Statistic,
  Badge,
  Radio,
  Empty,
  Skeleton,
  Spin,
  Switch,
  InputNumber,
  Slider,
  Cascader,
  TreeSelect,
  Descriptions,
  Image,
  Tooltip,
  Space,
  Collapse,
  Progress,
  Carousel,
  Segmented,
} from 'ant-design-vue';

const components = [
  Button,
  Modal,
  Layout,
  Menu,
  Breadcrumb,
  Input,
  Form,
  Checkbox,
  Select,
  DatePicker,
  Table,
  Tag,
  Divider,
  Pagination,
  Drawer,
  Upload,
  Popconfirm,
  Row,
  Col,
  ConfigProvider,
  Tabs,
  List,
  Dropdown,
  Tree,
  Popover,
  Avatar,
  Card,
  Statistic,
  Badge,
  Radio,
  Empty,
  Skeleton,
  Spin,
  Switch,
  InputNumber,
  Slider,
  Cascader,
  TreeSelect,
  Descriptions,
  Image,
  Tooltip,
  Space,
  Collapse,
  Progress,
  Carousel,
]

export default { 
  install: (app) => {
    components.forEach(component => {
      app.use(component)
    })

    // 注册全局方法,以下使用方法
    // import useGetGlobalProperties from '@/hooks/useGlobal'
    // const { $confirm } = useGetGlobalProperties()
    
    app.config.globalProperties.$confirm = (cb, title = '该操作不可逆，是否继续?') => {
      Modal.confirm({
        title,
        icon: createVNode(ExclamationCircleOutlined),
        onOk () {
          cb()
        },
      });
    }
  }
}
import { createRouter, createWebHashHistory } from 'vue-router'
import { message } from 'ant-design-vue';
const routes = [
    {
        path: '/',
        name: 'Index',
        linkActiveClass: 'on',
        component: () => import('@/components/Layout'),
        redirect: '/home',
        children: [
            // 首页
            {
                path: '/home',
                name: 'Home',
                component: () => import('@/views/Home'),
            },
            // 中心概况
            {
                path: '/centerProfile',
                name: 'CenterProfile',
                component: () => import('@/views/CenterProfile'),
                redirect: '/centerProfile/intro',
                children: [
                    {
                        path: 'intro',
                        name: 'Intro',
                        component: () => import('@/views/CenterProfile/Content.vue')
                    },
                    {
                        path: 'organizationalStructure',
                        name: 'OrganizationalStructure',
                        component: () => import('@/views/CenterProfile/Content.vue')
                    },
                    {
                        path: 'achievement',
                        name: 'Achievement',
                        component: () => import('@/views/CenterProfile/Content.vue')
                    },
                    {
                        path: 'contactInformation',
                        name: 'ContactInformation',
                        component: () => import('@/views/CenterProfile/Content')
                    },
                ]
            },
            // 数据一张图
            {
                path: '/dataMap',
                name: 'DataMap',
                component: () => import('@/views/DataMap'),
                redirect: '/dataMap/dailyImages',
                children: [
                    {
                        path: 'dailyImages',
                        name: 'DailyImages',
                        component: () => import('@/views/DataMap')
                    },
                    {
                        path: 'weeklyImages',
                        name: 'WeeklyImages',
                        component: () => import('@/views/DataMap')
                    },
                    {
                        path: 'thematicResultsQuery',
                        name: 'ThematicResultsQuery',
                        component: () => import('@/views/DataMap')
                    },
                    {
                        path: 'imageDataQuery',
                        name: 'ImageDataQuery',
                        component: () => import('@/views/DataMap')
                    },
                    {
                        path: 'customizedData',
                        name: 'CustomizedData',
                        component: () => import('@/views/DataMap')
                    },
                ]
            },
            //应用案例
            {
                path: '/applicationCase',
                name: 'ApplicationCase',
                component: () => import('@/views/ApplicationCase'),
                redirect: '/applicationCase/demonstrate',
                children: [
                    // 高分专项应用示范
                    {
                        path: 'demonstrate',
                        name: 'Demonstrate',
                        component: () => import('@/views/ApplicationCase/Content.vue')
                    },
                    // 高分应用案例
                    {
                        path: 'case',
                        name: 'Case',
                        component: () => import('@/views/ApplicationCase/Content.vue')
                    },
                    {
                        path: 'applicationCaseDetail',
                        name: 'ApplicationCaseDetail',
                        component: () => import('@/components/Detail')
                    }
                ]
            },
            //AI算法模型
            //   {
            //     path: '/algorithmModel',
            //     name: 'AlgorithmModel',
            //     component: () => import('@/views/AlgorithmModel'),
            //   },
            //行业培训
            {
                path: '/industryTraining',
                name: 'IndustryTraining',
                component: () => import('@/views/IndustryTraining'),
                redirect: '/industryTraining/trainingVideo',
                children: [
                    {
                        path: 'trainingVideo',
                        name: 'TrainingVideo',
                        component: () => import('@/views/IndustryTraining/Content')
                    },
                    {
                        path: 'trainingPPT',
                        name: 'TrainingPPT',
                        component: () => import('@/views/IndustryTraining/Content')
                    },
                    // {
                    //     path: 'publicationDetail',
                    //     name: 'PublicationDetail',
                    //     component: () => import('@/components/Detail')
                    // }
                ]
            },
            //新闻中心
            {
                path: '/newsCenter',
                name: 'NewsCenter',
                component: () => import('@/views/NewsCenter'),
                redirect: '/newsCenter/news',
                children: [
                    {
                        path: 'news',
                        name: 'News',
                        component: () => import('@/views/NewsCenter/Content.vue'),
                    },
                    {
                        path: 'notice',
                        name: 'Notice',
                        component: () => import('@/views/NewsCenter/Notice')
                    },
                    {
                        path: 'workDynamics',
                        name: 'WorkDynamics',
                        component: () => import('@/views/NewsCenter/Content.vue')
                    },
                    {
                        path: 'newsDetail',
                        name: 'NewsDetail',
                        component: () => import('@/components/Detail')
                    }
                ]
            },
            //政策法规
            {
                path: '/policies',
                name: 'Policies',
                component: () => import('@/views/Policies'),
                redirect: '/policies/regulations',
                children: [
                    {
                        path: 'regulations',
                        name: 'Regulations',
                        component: () => import('@/views/Policies/Content.vue')
                    },
                    {
                        path: 'dataSpecification',
                        name: 'DataSpecification',
                        component: () => import('@/views/Policies/Content.vue')
                    },
                    {
                        path: 'centerSpecification',
                        name: 'CenterSpecification',
                        component: () => import('@/views/Policies/Content.vue')
                    },
                    {
                        path: 'regulationsDetail',
                        name: 'RegulationsDetail',
                        component: () => import('@/components/Detail')
                    }
                ]
            },
            //用户信息
            {
                path: '/user',
                name: 'User',
                component: () => import('@/views/User'),
                redirect: '/user/summary',
                children: [
                    {
                        path: 'summary',
                        name: 'Summary',
                        component: () => import('@/views/User/Summary')
                    },
                    {
                        path: 'information',
                        name: 'Information',
                        component: () => import('@/views/User/Information')
                    },
                    {
                        path: 'customization',
                        name: 'Customization',
                        component: () => import('@/views/User/Customization')
                    },
                    {
                        path: 'application',
                        name: 'Application',
                        component: () => import('@/views/User/Application')
                    },
                    {
                        path: 'feedback',
                        name: 'Feedback',
                        component: () => import('@/views/User/Feedback')
                    },
                    {
                        path: 'message',
                        name: 'Message',
                        component: () => import('@/views/User/Message')
                    },
                ]
            },
            // 知识库查询
            {
                path: '/knowledgeQuery',
                name: 'KnowledgeQuery',
                component: () => import('@/views/KnowledgeQuery'),
                redirect: '/knowledgeQuery/wordage',
                children: [
                    {
                        path: 'wordage',
                        name: 'Wordage',
                        component: () => import('@/views/KnowledgeQuery/Wordage')
                    },
                    {
                        path: 'image',
                        name: 'Image',
                        component: () => import('@/views/KnowledgeQuery/Wordage')
                    },
                    {
                        path: 'audio',
                        name: 'Audio',
                        component: () => import('@/views/KnowledgeQuery/Wordage')
                    },
                    {
                        path: 'video',
                        name: 'Video',
                        component: () => import('@/views/KnowledgeQuery/Wordage')
                    },
                    {
                        path: 'data',
                        name: 'Data',
                        component: () => import('@/views/KnowledgeQuery/Wordage')
                    },
                    {
                        path: 'experience',
                        name: 'Experience',
                        component: () => import('@/views/KnowledgeQuery/Wordage')
                    },
                ]
            },

        ]
    },
    //文件预览
    {
        path: '/filePreView',
        name: 'FilePreView',
        component: () => import('@/components/FilePreView')
    },
    // 登录
    {
        path: '/login',
        name: 'Login',
        meta: {},
        component: () => import('@/views/User/Login.vue'),
    },
    // 注册
    {
        path: '/register',
        name: 'Register',
        meta: {},
        component: () => import('@/views/User/Register.vue'),
    },
    // 找回密码
    {
        path: '/forget',
        name: 'Forget',
        meta: {},
        component: () => import('@/views/User/Forget.vue'),
    },
    {
        path: '/:error*',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
